const initialDecoro = localStorage.getItem("decoro");

const initialState = initialDecoro
  ? { decoro: initialDecoro }
  : {
      decoro: {
        lista_formati: null,
        lista_finiture: null,
        lista_spessori: null,
      },
    };

export const decoro = {
  namespaced: true,
  state: initialState,
  actions: {
    updateFormati(context, listaFormati) {
      context.commit("updatedFormati", listaFormati);
    },
    updateSpessori(context, listaSpessori) {
      context.commit("updatedSpessori", listaSpessori);
    },
    updateFiniture(context, listaFiniture) {
      context.commit("updatedFiniture", listaFiniture);
    },
  },
  mutations: {
    updatedFormati(state, listaFormati) {
      state.lista_formati = listaFormati;
    },
    updatedSpessori(state, listaSpessori) {
      state.lista_spessori = listaSpessori;
    },
    updatedFiniture(state, listaFiniture) {
      state.lista_finiture = listaFiniture;
    },
  },
  getters: {
    lista_spessori(state) {
      return state.decoro.lista_spessori;
    },
    lista_formati(state) {
      return state.decoro.lista_formati;
    },
    lista_finiture(state) {
      return state.decoro.lista_finiture;
    },
  },
};
