import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "@/config/msal.config";

// define msal instance
export const msal = new PublicClientApplication(msalConfig);
await msal.initialize();

// define functions on msal
export const Auth = {
  // Initialize msal and return active account
  async initialize(client) {
    await msal.handleRedirectPromise();

    if (client) {
      msal.setNavigationClient(client);
    }

    const accounts = msal.getAllAccounts(); // retrieve all accounts signed in
    if (accounts?.length) {
      // if there is more than one account
      msal.setActiveAccount(accounts[0]);
    }

    return msal.getActiveAccount();
  },

  // Login with the specified account, if there is a problem on login clear the local storage and try again
  async login() {
    return msal
      .loginPopup({
        redirectUri: msalConfig.auth.redirectUri,
        scopes: loginRequest.scopes,
      })
      .then((result) => {
        console.log("Logged in with: ", result);
        console.log("Account is: ", result.account);
        msal.setActiveAccount(result.account);
        return result.account;
      })
      .catch((error) => {
        if (error.errorCode === "interaction_in_progress") {
          localStorage.clear();
          console.error(error.errorCode);
          return this.login();
        }

        throw new Error(error.errorMessage);
      });
  },

  // Logout calling the logout popup
  async logout() {
    return msal
      .logoutPopup({
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        // mainWindowRedirectUri: msalConfig.auth.mainWindowsRedirectUri,
      })
      .catch((error) => {
        console.error(error);
        if (error.errorCode !== "user_cancellation") {
          throw new Error(error.errorMessage);
        }
      });
  },

  // Retrieve the token provided by adal
  async getToken() {
    return msal
      .acquireTokenSilent(loginRequest)
      .catch(async (error) => {
        // try to acquire token silently
        if (error instanceof InteractionRequiredAuthError) {
          // if there was an error during the process
          return msal.acquireTokenPopup(loginRequest); // try to acquire the token by asking explicit consent to the user
        }
        throw Error;
      })
      .then((result) => {
        return result.accessToken;
      });
  },
};
