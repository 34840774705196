<script>
export default {
  name: "NotAuthorized",
};
</script>

<template>
  <div class="w-100 d-flex justify-center">
    <v-empty-state
      class="w-100"
      headline="401"
      title="Not Authorized"
      image="/img/logo_abet.svg"
      max-width="500px"
    ></v-empty-state>
  </div>
</template>

<style scoped></style>
