<script>
import { countryName } from "@/utils/helper";
import axios from "axios";
import {
  API_URL,
  defaultTimeout,
  notifyError,
  notifySaving,
} from "@/utils/const";
import authHeader from "@/services/data.service";

export default {
  name: "consociate-component",
  emits: ["update"],
  props: {
    id: { required: true, type: Number },
    consociate: { required: true, type: Array },
    hiddencons: { required: true, type: Array },
  },
  mounted() {
    this.selected_consociate = this.hiddencons;
    this.allSelected =
      this.selected_consociate.length === this.consociate.length;
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    countryName,
    selectAll() {
      this.selected_consociate = [];

      if (this.allSelected) {
        this.allSelected = false;
      } else {
        for (let consociata of this.consociate) {
          this.selected_consociate.push(consociata);
        }
        this.allSelected = true;
      }
      this.updateHiddenCons();
    },
    checkSelection() {
      this.allSelected =
        this.selected_consociate.length === this.consociate.length;
      this.updateHiddenCons();
    },
    async updateHiddenCons() {
      const body = {
        _id: this.id,
        consociate_nascoste: this.selected_consociate,
      };
      await axios
        .put(API_URL + `decorativi/nascondi`, body, { headers: authHeader() })
        .then((response) => {
          if (response.data) {
            this.$emit("update", this.selected_consociate);
          }
          this.text = notifySaving;
          this.snackbar = true;
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
  },
  data() {
    return {
      selected_consociate: [],
      allSelected: false,
      snackbar: false,
      text: "",
    };
  },
};
</script>

<template>
  <div class="wk_consociate_component">
    <div class="wk_checkbox_ww">
      <v-checkbox
        label="SELEZIONA TUTTI"
        @click="selectAll"
        v-model="allSelected"
        color="black"
        hide-details
      ></v-checkbox>
    </div>
    <div class="wk_checkbox_consociate">
      <v-checkbox
        v-for="consociata in consociate"
        :key="consociata"
        :label="countryName(consociata)"
        :value="consociata"
        v-model="selected_consociate"
        @update:model-value="checkSelection"
        color="black"
        hide-details
        class="wk_singola_checkbox"
      ></v-checkbox>
    </div>
    <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"
          >Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.v-checkbox >>> .v-selection-control {
  min-height: unset;
}

.wk_checkbox_ww {
  margin-top: 20px;
}

.wk_checkbox_consociate {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
}

.wk_singola_checkbox {
  display: inline-block;
  width: 20% !important;
  font-size: 14px;
}

.v-checkbox >>> .v-selection-control .v-label {
  white-space: normal;
  word-break: break-word;
  height: 100%;
  font-size: 14px;
}
</style>
