// utils
export const countries = [
  "au",
  "be",
  "cn",
  "fr",
  "de",
  "it",
  "nl",
  "na",
  "pl",
  "ru",
  "es",
  "ch",
  "uk",
];

// display messages
export const notifyLoading = "Caricamento completato";
export const notifySaving = "Salvataggio completato";
export const notifyError = "Qualcosa è andato sorto. Riprova più tardi";
export const notifyDeletion = "Cancellazione completata";
export const notifyConsAlreadyExist =
  "Standard già esistente per la consociata selezionata.";

export const restoreConfirmation =
  "Cliccando su conferma, procederai al ripristino dei dati di producibilità più recenti. Tutti i dati di producibilità aggiunti sul sito verranno cancellati. Sei sicuro di voler continuare?";
export const duplicateConfirmation =
  "Cliccando su conferma, procederai alla creazione di un nuovo set di dati di producibilità modificabili dal sito. I dati precenti non verranno cancellati.";

export const defaultTimeout = 2000;
// url
export const THIS_URL =
  process.env.VUE_APP_NODE_ENV === "production"
    ? process.env.VUE_APP_THIS_URL_PROD
    : process.env.VUE_APP_THIS_URL_DEV;
export const API_URL =
  process.env.VUE_APP_NODE_ENV === "production"
    ? process.env.VUE_APP_API_URL_PROD
    : process.env.VUE_APP_API_URL_DEV;
