import { createStore } from "vuex";
import { auth } from "@/store/auth.module";
import { stock } from "@/store/stock.module";
import { prod } from "@/store/prod.module";
import { decoro } from "@/store/decoro.module";

export default createStore({
  modules: {
    auth,
    stock,
    prod,
    decoro,
  },
});
