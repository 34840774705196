<template>
  <div class="wk_producibilita d-flex flex-column w-100">
    <div id="search-bar" class="bg-grey-lighten-3 py-2">
      <div class="wk_searchbar_row large-wrapper" id="search-bar-first-row">
        <v-combobox
          label="codice tipo"
          :items="search_fields.tipi"
          v-model="selectedTipo"
          class="wk_autocomplete"
          clearable
          hide-no-data
        ></v-combobox>
        <v-combobox
          label="codice colore 1"
          :items="search_fields.colori1"
          item-title="CDCOL"
          item-name="CDCOL"
          item-value="CDCOL"
          v-model="selectedColore1"
          class="wk_autocomplete"
          clearable
          hide-no-data
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item.raw.CDCOL !== '' ? item.raw.CDCOL : 'Senza codice'"
              :subtitle="item.raw.CDCOL_DES ? item.raw.CDCOL_DES : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
          label="codice finitura 1"
          :items="search_fields.finiture1"
          item-title="CDFIN"
          item-name="CDFIN"
          item-value="CDFIN"
          v-model="selectedFinitura1"
          class="wk_autocomplete"
          clearable
          hide-no-data
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item.raw.CDFIN !== '' ? item.raw.CDFIN : 'Senza codice'"
              :subtitle="item.raw.CDFIN_DES ? item.raw.CDFIN_DES : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
          label="spessore (mm)"
          :items="search_fields.spessori"
          v-model="selectedSpessore"
          class="wk_autocomplete"
          clearable
          hide-no-data
        ></v-combobox>
        <v-combobox
          label="lunghezza (mm)"
          :items="search_fields.lunghezze"
          v-model="selectedLunghezza"
          class="wk_autocomplete"
          clearable
          hide-no-data
        ></v-combobox>
        <v-combobox
          label="larghezza (mm)"
          :items="search_fields.larghezze"
          v-model="selectedLarghezza"
          class="wk_autocomplete"
          clearable
          hide-no-data
        ></v-combobox>
      </div>
      <div class="wk_searchbar_row large-wrapper" id="search-bar-second-row">
        <v-combobox
          label="codice collezione"
          :items="search_fields.collezioni"
          v-model="selectedCollezione"
          class="wk_autocomplete"
          clearable
          hide-no-data
        ></v-combobox>
        <v-combobox
          label="codice colore 2"
          :items="search_fields.colori2"
          item-title="CDCO2"
          item-name="CDCO2"
          item-value="CDCO2"
          v-model="selectedColore2"
          class="wk_autocomplete"
          clearable
          hide-no-data
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item.raw.CDCO2 !== '' ? item.raw.CDCO2 : 'Senza codice'"
              :subtitle="item.raw.CDCOL_DES2 ? item.raw.CDCOL_DES2 : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
          label="codice finitura 2"
          :items="search_fields.finiture2"
          item-title="CDFI2"
          item-name="CDFI2"
          item-value="CDFI2"
          v-model="selectedFinitura2"
          class="wk_autocomplete"
          clearable
          hide-no-data
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item.raw.CDFI2 !== '' ? item.raw.CDFI2 : 'Senza codice'"
              :subtitle="item.raw.CDFIN_DES2 ? item.raw.CDFIN_DES2 : ''"
            ></v-list-item>
          </template>
        </v-combobox>
        <v-combobox
          label="dimensione"
          :items="search_fields.dimensioni"
          v-model="selectedDimensione"
          class="wk_autocomplete"
          clearable
          hide-no-data
        ></v-combobox>
        <div class="d-flex justify-end" id="search-button">
          <v-btn @click="filter()" variant="flat" color="black">Search</v-btn>
          <v-btn @click="reset()" variant="tonal" color="black">Reset</v-btn>
        </div>
      </div>
    </div>

    <v-alert
      class="my-1 large-wrapper"
      v-if="searched && filteredList.length === 0"
      text="Nessun dato soddisfa la tua ricerca."
      title="Nessun risultato"
      type="error"
      density="comfortable"
      closable
    ></v-alert>

    <v-alert
      class="my-1 large-wrapper"
      v-if="
        searched && filteredList.length > 1000 && filteredList.length < 100000
      "
      text="Sono stati identificati più di 1000 risultati. Si consiglia di aumentare i parametri di ricerca per ridurre il numero totale di risultati"
      title="Attenzione"
      type="warning"
      density="compact"
      closable
    ></v-alert>

    <v-alert
      class="my-1 large-wrapper"
      v-if="searched && filteredList.length > 99999"
      text="La ricerca ha prodotto più di 100.000 risultati ed è stata limitata. Si consiglia di aumentare i parametri di ricerca per ridurre il numero totale di risultati"
      title="Attenzione"
      type="warning"
      density="compact"
      closable
    ></v-alert>

    <div
      class="space-top align-center justify-center text-center"
      v-if="loading"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="black"
        indeterminate
      ></v-progress-circular>
    </div>

    <DataTable
      v-else
      class="wk_prod_table large-wrapper p-datatable-sm"
      style="font-size: 0.875rem"
      v-show="filteredList.length > 0"
      :value="filteredList"
      v-model:filters="filters"
      filterDisplay="row"
      :reorderable-columns="true"
      resizableColumns
      columnResizeMode="expand"
      stripedRows
      paginator
      :rows="25"
      :totalRecors="filteredList.length"
      :rowsPerPageOptions="[5, 10, 25, 50, 100]"
      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="{first} a {last} di {totalRecords}"
    >
      <template #header>
        <!--        <div class="wk_searchbar">-->
        <!--          <InputText-->
        <!--            v-model="filters['global'].value"-->
        <!--            placeholder="Ricerca globale"-->
        <!--          />-->
        <!--        </div>-->
        <div class="wk_columns_select text-right">
          Seleziona le colonne:
          <MultiSelect
            :modelValue="selectedColumns"
            :options="headerList"
            optionLabel="header"
            @update:modelValue="onToggle"
            :maxSelectedLabels="5"
            class="ma-1"
          />
        </div>
      </template>

      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.key + '_' + index"
        :field="col.field"
        :header="col.header"
        sortable
      ></Column>
    </DataTable>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/styles";

.wk_producibilita {
  padding-bottom: 100px;
}

.large-wrapper {
  margin: 0 auto;
  width: 90%;
}

#search-button {
  width: 33.33%;
  max-width: calc(33.33% - 20px);
  margin: 20px 10px;
}

.space-top {
  margin-top: 80px;
}

.wk_searchbar_row {
  display: flex;
  justify-content: flex-start;
}

.wk_searchbar {
  display: flex;
  justify-content: center;
  margin: 25px 0;

  input {
    min-width: 250px;
  }
}

.wk_autocomplete {
  max-width: calc(16.666% - 20px);
  margin: 0 10px;
}

.v-data-table-header__content span {
  font-weight: 600;
}

// remove margin bottom 100px
.p-datatable.p-component.p-datatable-responsive-scroll {
  margin-bottom: 0;
}

.wk_columns_select .p-multiselect .p-multiselect-label {
  padding: 10px !important;
}
</style>

<script>
import axios from "axios";
import search_fields_json from "../data/search_fields_prod.json";
import { API_URL } from "@/utils/const";
import authHeader from "@/services/data.service";
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      ready: false,
      clearable: true,
      loading: false,
      // search_fields: [],
      // data from APIs
      search_fields: {
        tipi: [],
        colori1: [],
        colori2: [],
        finiture1: [],
        finiture2: [],
        spessori: [],
        dimensioni: [],
        lunghezze: [],
        larghezze: [],
        collezioni: [],
      },
      // selected data
      selectedTipo: null,
      selectedColore1: null,
      selectedColore2: null,
      selectedFinitura1: null,
      selectedFinitura2: null,
      selectedSpessore: null,
      selectedDimensione: null,
      selectedLunghezza: null,
      selectedLarghezza: null,
      selectedCollezione: null,
      selectedColumns: [],
      headerList: [
        { field: "CDTIP", header: "Codice Tipo" },
        { field: "CDCOL", header: "Colore 1" },
        { field: "CDCOL_DES", header: "Descrizione colore 1" },
        { field: "CDCO2", header: "Colore 2" },
        { field: "CDCOL_DES2", header: "Descrizione colore 2" },
        { field: "CDFIN", header: "Finitura 1" },
        { field: "CDFIN_DES", header: "Descrizione finitura 1" },
        { field: "CDFI2", header: "Finitura 2" },
        { field: "CDFIN_DES2", header: "Descrizione finitura 2" },
        { field: "SPESS", header: "Spessore" },
        { field: "CDDIM", header: "Dimensione" },
        { field: "LNGDM", header: "Lunghezza" },
        { field: "LRGDM", header: "Larghezza" },
        { field: "GRSTP", header: "Collezione" },
        { field: "TIPST", header: "Stato" },
      ],
      filteredList: [],
      search: "",
      searched: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CDTIP: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDFIN: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDFIN_DES: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDFI2: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDFIN_DES2: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDCOL: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDCOL_DES: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDCO2: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDCOL_DES2: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        SPESS: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        CDDIM: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        LNGDM: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        LRGDM: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        GRSTP: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        TIPST: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
    };
  },
  computed: {
    ...mapGetters("prod", ["tipi"]),
    ...mapGetters("prod", ["colori1"]),
    ...mapGetters("prod", ["colori2"]),
    ...mapGetters("prod", ["finiture1"]),
    ...mapGetters("prod", ["finiture2"]),
    ...mapGetters("prod", ["spessori"]),
    ...mapGetters("prod", ["lunghezze"]),
    ...mapGetters("prod", ["larghezze"]),
    ...mapGetters("prod", ["collezioni"]),
  },
  mounted() {
    if (this.$route.meta.title) {
      document.title = this.$route.meta.title;
    }

    this.loading = true;

    this.search_fields = search_fields_json;

    // if (!this.tipi) {
    //   this.getTipi();
    // } else {
    //   this.search_fields.tipi = this.tipi;
    // }
    //
    // if (!this.colori1) {
    //   this.getColori1();
    // } else {
    //   this.search_fields.colori1 = this.colori1;
    // }
    //
    // if (!this.colori2) {
    //   this.getColori2();
    // } else {
    //   this.search_fields.colori2 = this.colori2;
    // }
    //
    // if (!this.finiture1) {
    //   this.getFiniture1();
    // } else {
    //   this.search_fields.finiture1 = this.finiture1;
    // }
    //
    // if (!this.finiture2) {
    //   this.getFiniture2();
    // } else {
    //   this.search_fields.finiture2 = this.finiture2;
    // }
    //
    // if (!this.spessori) {
    //   this.getSpessori();
    // } else {
    //   this.search_fields.spessori = this.spessori;
    // }
    //
    // if (!this.lunghezze) {
    //   this.getLunghezze();
    // } else {
    //   this.search_fields.lunghezze = this.lunghezze;
    // }
    //
    // if (!this.larghezze) {
    //   this.getLarghezze();
    // } else {
    //   this.search_fields.larghezze = this.larghezze;
    // }
    //
    // if (!this.collezioni) {
    //   this.getCollezioni();
    // } else {
    //   this.search_fields.collezioni = this.collezioni;
    // }

    this.selectedColumns = this.headerList;

    this.loading = false;
  },
  methods: {
    async getTipi() {
      await axios
        .get(API_URL + "prod/tipi", { headers: authHeader() })
        .then((response) => {
          this.search_fields.tipi = response.data;
          this.$store.dispatch("prod/updateTipi", response.data);
        })
        .catch((error) => console.error("Error while getting types", error));
    },
    async getColori1() {
      await axios
        .get(API_URL + "prod/colori1", { headers: authHeader() })
        .then((response) => {
          this.search_fields.colori1 = response.data;
          this.$store.dispatch("prod/updateColori1", response.data);
        })
        .catch((error) =>
          console.error("Error while getting colors (1)", error)
        );
    },
    async getColori2() {
      await axios
        .get(API_URL + "prod/colori2", { headers: authHeader() })
        .then((response) => {
          this.search_fields.colori2 = response.data;
          this.$store.dispatch("prod/updateColori2", response.data);
        })
        .catch((error) => console.error("Error while getting (2)", error));
    },
    async getFiniture1() {
      await axios
        .get(API_URL + "prod/finiture1", { headers: authHeader() })
        .then((response) => {
          this.search_fields.finiture1 = response.data;
          this.$store.dispatch("prod/updateFiniture1", response.data);
        })
        .catch((error) =>
          console.error("Error while getting finiture (1)", error)
        );
    },
    async getFiniture2() {
      await axios
        .get(API_URL + "prod/finiture2", { headers: authHeader() })
        .then((response) => {
          this.search_fields.finiture2 = response.data;
          this.$store.dispatch("prod/updateFiniture2", response.data);
        })
        .catch((error) =>
          console.error("Error while getting finiture (2)", error)
        );
    },
    async getSpessori() {
      await axios
        .get(API_URL + "prod/spessori", { headers: authHeader() })
        .then((response) => {
          this.search_fields.spessori = response.data;
          this.$store.dispatch("prod/updateSpessori", response.data);
        })
        .catch((error) => console.error("Error while getting spessori", error));
    },
    async getLunghezze() {
      await axios
        .get(API_URL + "prod/lunghezze", { headers: authHeader() })
        .then((response) => {
          this.search_fields.lunghezze = response.data;
          this.$store.dispatch("prod/updateLunghezze", response.data);
        })
        .catch((error) =>
          console.error("Error while getting lunghezze", error)
        );
    },
    async getLarghezze() {
      await axios
        .get(API_URL + "prod/larghezze", { headers: authHeader() })
        .then((response) => {
          this.search_fields.larghezze = response.data;
          this.$store.dispatch("prod/updateLarghezze", response.data);
        })
        .catch((error) =>
          console.error("Error while getting larghezze", error)
        );
    },
    async getCollezioni() {
      await axios
        .get(API_URL + "prod/collezioni", { headers: authHeader() })
        .then((response) => {
          this.search_fields.collezioni = response.data;
          this.$store.dispatch("prod/updateCollezioni", response.data);
        })
        .catch((error) =>
          console.error("Error while getting collezioni", error)
        );
    },
    async filter() {
      this.loading = true;
      this.searched = false;

      const queryParams = {
        tipo: this.selectedTipo,
        colore1: this.selectedColore1 ? this.selectedColore1.CDCOL : null,
        colore2: this.selectedColore2 ? this.selectedColore2.CDCO2 : null,
        finitura1: this.selectedFinitura1 ? this.selectedFinitura1.CDFIN : null,
        finitura2: this.selectedFinitura2 ? this.selectedFinitura2.CDFI2 : null,
        dimensione: this.selectedDimensione,
        spessore: this.selectedSpessore,
        lunghezza: this.selectedLunghezza,
        larghezza: this.selectedLarghezza,
        collezione: this.selectedCollezione,
      };
      await axios
        .get(API_URL + "prod/records/filter", {
          headers: authHeader(),
          params: queryParams,
        })
        .then((response) => {
          if (response.data) {
            this.filteredList = response.data.filter((record) => {
              // remove record that has status 4 or 5
              return record.TIPST < 4;
            });
          } else {
            this.filteredList = {};
          }
        })
        .catch((error) => {
          console.error("Error while getting the search result", error);
        });

      this.loading = false;
      this.searched = true;
    },
    onToggle(value) {
      this.selectedColumns = this.headerList.filter((col) =>
        value.includes(col)
      );
    },
    reset() {
      this.selectedTipo = null;
      this.selectedColore1 = null;
      this.selectedColore2 = null;
      this.selectedFinitura1 = null;
      this.selectedFinitura2 = null;
      this.selectedDimensione = null;
      this.selectedSpessore = null;
      this.selectedLunghezza = null;
      this.selectedLunghezza = null;
      this.selectedCollezione = null;
      this.filteredList = [];
      this.searched = false;
    },
  },
};
</script>
