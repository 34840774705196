<template>
  <div class="d-flex flex-column wrapper">
    <div class="wk_breadcrumbs">
      <v-breadcrumbs class="text-grey pa-0" :items="breadcrumb" divider="\">
        <template v-slot:title="{ item }">
          {{ item.title }}
        </template>
      </v-breadcrumbs>
      <div class="wk_collection_heading">
        <h1 class="wk_page_title">{{ selectedCollezione }}</h1>
        <!--        <span v-if="flag" :class="`fi fi-${flag}`"></span>-->
        <div class="wk_flags">
          <span
            v-for="flag in flags"
            v-bind:key="flag"
            :class="`fi fi-${flag}`"
          ></span>
        </div>
      </div>
    </div>

    <div class="align-center justify-center text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </div>

    <DataTable
      :value="collezione"
      :reorderable-columns="true"
      @rowReorder="onRowReorder"
      v-model:editingRows="editingRows"
      editMode="row"
      @row-edit-init="onRowEditInit"
      class="p-datatable-sm"
      lazy
      resizableColumns
      columnResizeMode="expand"
      @row-edit-save="onRowEditSave"
    >
      <Column
        v-if="isAdmin"
        rowReorder
        headerStyle="width: 3rem"
        :reorderableColumn="false"
      />
      <Column field="id" header="img">
        <template #body="{ data, field }">
          <div
            class="wk_color_img"
            :style="
              data['collection_img']
                ? 'background-image: url(https://abetlaminati.com/CAMPIONI/' +
                  data['collection_img'] +
                  '.jpg)'
                : 'background-image: url(https://abetlaminati.com/CAMPIONI/' +
                  data[field] +
                  '_S.jpg)'
            "
          >
            <span
              class="wk_color_img_error"
              style="
                background-image: url(https://prod.abetlaminati.com/img/abet_alert.png);
              "
            ></span>
          </div>
        </template>
      </Column>
      <Column field="id" header="code"></Column>
      <Column field="name" header="name">
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column
        field="finitura_std"
        header="finish"
        bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
        class="wk_column_grey"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column
        field="spessore_std"
        header="thickness"
        bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
        class="wk_column_grey"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" style="max-width: 100px" />
        </template>
      </Column>
      <Column
        field="formato_std"
        header="size"
        bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
        class="wk_column_grey"
      >
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>
      <Column field="novita" header="new" bodyStyle="padding: 0 20px;">
        <template #body="{ data, field }">
          <Checkbox v-model="data[field]" :binary="true" disabled />
        </template>
        <template #editor="{ data, field }">
          <Checkbox v-model="data[field]" :binary="true" />
        </template>
      </Column>
      <Column field="consociata" header="subsidiary">
        <template #body="{ data, field }">
          <div class="wk_flags" v-if="data[field]">
            <span
              v-for="flag in flags"
              v-bind:key="flag"
              :class="`fi fi-${flag}`"
            ></span>
          </div>
          <div class="wk_flags" v-else>
            <span class="fi fi-it"></span>
          </div>
        </template>
      </Column>
      <Column
        field="action_edit"
        :rowEditor="!isNewUser"
        style="width: 50px; min-width: 8rem"
        bodyStyle="text-align:right"
      ></Column>
    </DataTable>

    <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"
          >Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
/*@import "primevue/resources/themes/lara-light-green/theme.css";*/
@import "../../node_modules/flag-icons/css/flag-icons.min.css";

.wk_breadcrumbs {
  font-size: 20px;
  margin: 50px 0;
  display: block;
}

.wk_collection_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.wk_flags {
  display: flex;
  gap: 0 5px;
}

.wk_page_title {
  margin-top: 30px;
  font-weight: 600;
}

.wk_color_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200% auto;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}

input.p-inputtext.p-component {
  transform: none;
  position: relative;
}
</style>

<script>
import axios from "axios";
import {
  API_URL,
  defaultTimeout,
  notifyError,
  notifyLoading,
  notifySaving,
} from "@/utils/const";
import authHeader from "@/services/data.service";

export default {
  data() {
    return {
      breadcrumb: [],
      loading: false,
      // data from APIs
      collezione: [],
      cons: null,
      selectedCollezione: null,
      editingRows: [],
      text: "",
      snackbar: false,
      editing: true,
      isAdmin: false,
      isNewUser: false,
    };
  },
  computed: {
    flags() {
      if (this.cons === "na") {
        return ["us", "ca"];
      } else if (this.cons === "all") {
        return ["it"];
      } else if (this.cons === "none") {
        return [null];
      } else {
        return [this.cons];
      }
    },
  },
  mounted() {
    this.loading = true;

    this.getRole();

    this.selectedCollezione = this.$route.params.collezione;
    this.getCollezione(this.selectedCollezione);

    if (this.$route.meta.title && this.selectedCollezione) {
      document.title = this.$route.meta.title + this.selectedCollezione;
    } else {
      document.title = "ABET Collezione";
    }

    this.breadcrumb = [
      {
        title: "collections",
        disabled: false,
        href: "/collections",
      },
      {
        title: this.selectedCollezione,
        disabled: true,
        href: "",
      },
    ];

    this.loading = false;
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    async getRole() {
      await axios
        .get(API_URL + "auth", {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.data) {
            if (
              response.data.data.role === "admin" &&
              response.data.data.cons === "all"
            ) {
              this.isAdmin = true;
            }

            if (response.data.data.cons === "none") {
              this.isNewUser = true;
            }
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async getCollezione(collectionName) {
      await axios
        .get(API_URL + "decorativi/" + collectionName, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.data) {
            this.collezione = response.data.data.collezione;
            this.cons = response.data.data.cons;
            this.text = notifyLoading;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async updateIndex(id, index) {
      const body = { _id: id, index: index };
      await axios
        .put(API_URL + "decorativi/reorder", body, { headers: authHeader() })
        .then(() => {
          this.text = notifySaving;
          this.snackbar = true;
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    onRowReorder(event) {
      this.collezione = event.value;
      for (let i = 0; i < this.collezione.length; i++) {
        if (i !== this.collezione[i].index) {
          this.updateIndex(this.collezione[i]._id, i);
          this.collezione[i].index = i;
        }
      }
    },
    onRowEditInit(event) {
      if (this.isAdmin) {
        let currentPath = this.$route.path;
        this.$router.push({ path: `${currentPath}/${event.data._id}` });
      }
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      this.collezione[index] = newData;
    },
    checkIfImageExists(url) {
      const img = new Image();
      img.src = url;
      console.log(img);
      if (img.complete) {
        return img.src;
      } else {
        img.onload = () => {
          return img.src;
        };

        img.onerror = () => {
          return img.src;
        };
      }
    },
  },
};
</script>
