import { jwtDecode } from "jwt-decode";

export const countryName = (country) => {
  switch (country.toLowerCase()) {
    case "au":
      return "Australia";
    case "be":
      return "Belgium";
    case "cn":
      return "China";
    case "fr":
      return "France";
    case "de":
      return "Germany";
    case "it":
      return "Italy";
    case "nl":
      return "Netherlands";
    case "na":
      return "North America";
    case "pl":
      return "Poland";
    case "ru":
      return "Russia";
    case "es":
      return "Spain";
    case "ch":
      return "Switzerland";
    case "uk":
      return "United Kingdom";
    default:
      return "";
  }
};

export const availabilityColor = (availability) => {
  switch (availability) {
    case 1:
      return "orange";
    case 2:
      return "gold";
    case 3:
      return "green";
    default:
      return "orange";
  }
};
export const getToken = () => {
  let isPresent = !!localStorage.getItem("user");
  if (isPresent) {
    try {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const getPayload = () => {
  let token = getToken();
  if (token) {
    return jwtDecode(token);
  }
  return null;
};

export const getExp = () => {
  let payload = getPayload();
  if (payload) {
    return payload.exp;
  }
  return null;
};

export const getUsername = () => {
  let payload = getPayload();
  if (payload) {
    return payload.username;
  }
  return null;
};

export const getRole = () => {
  let payload = getPayload();
  if (payload) {
    return payload.role;
  }
  return null;
};

export const getCons = () => {
  let payload = getPayload();
  if (payload) {
    return payload.cons;
  }
  return null;
};

export const getIat = () => {
  let payload = getPayload();
  if (payload) {
    return payload.iat;
  }
  return null;
};

export const userHasRole = (role) => {
  let payload = getPayload();
  if (payload) {
    return payload.role === role;
  }
  return false;
};
