<template>
  <div class="wk_collezioni_view wrapper">
    <div class="wk_collezioni_view_header" v-if="!loading">
      <div class="wk_breadcrumbs">
        <v-breadcrumbs class="text-grey pa-0" :items="breadcrumb" divider="\">
          <template v-slot:title="{ item }">
            {{ item.title }}
          </template>
        </v-breadcrumbs>
      </div>
    </div>

    <div class="wk_collezioni_view_content">
      <div id="left-div" class="">
        <Decorativo
          :_id="decorativo._id"
          :id="decorativo.id"
          :name="decorativo.name"
          :collezione="selectedCollezione"
          :extra_img="decorativo.collection_img"
          v-if="decorativo"
          @update="onUpdateImage($event)"
        ></Decorativo>
      </div>

      <div id="right-div" class="d-flex flex-column w-66">
        <div
          class="wk-change-collection mx-2 flex-row d-flex align-center mb-10"
        >
          <span>modifica il decoro in altra collezione:</span>
          <v-autocomplete
            v-if="collezioniCorrelate"
            v-model="selectedDecorativo"
            :items="collezioniCorrelate"
            class="wk-change-collection__select mx-3 my-0"
            item-title="Decorativo"
            item-value="Decorativo"
            item-name="Decorativo"
            @update:model-value="
              goToDecorativo(
                selectedDecorativo.collection_name,
                selectedDecorativo._id
              )
            "
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-bind="props"
                :prepend-avatar="getImgSrc(item.raw.id)"
                :title="displayDecorativoName(item.raw.id, item.raw.name)"
                :subtitle="
                  displayDecorativoSpecs(
                    item.raw.collection_id,
                    item.raw.collection_name,
                    item.raw.spessore_std,
                    item.raw.formato_std,
                    item.raw.finitura_std
                  )
                "
              ></v-list-item>
            </template>
          </v-autocomplete>
        </div>

        <div id="specs_decorativo" class="wk_std_globale">
          <Standard
            :_id="decorativo._id"
            :lista_spessori="values.lista_spessori"
            :lista_finiture="values.lista_finiture"
            :lista_formati="values.lista_formati"
            :spessore="decorativo.spessore_std"
            :finitura="decorativo.finitura_std"
            :formato="decorativo.formato_std"
            v-if="
              decorativo.spessore_std &&
              decorativo.finitura_std &&
              decorativo.lista_formati
            "
          ></Standard>
        </div>

        <!-- Sezione standard consociate -->
        <div class="wk_std_consociate">
          <div class="w-100">
            <div
              class="wk_std_consociate_header w-25 mx-2 text-right align-self-center"
            >
              <b>ALTRI STANDARD</b>
            </div>
          </div>
          <Standard
            v-for="decorativoConsociata in decorativiConsociate"
            :key="decorativoConsociata.nazione"
            :_id="decorativoConsociata._id"
            :nazione="decorativoConsociata.nazione"
            :lista_spessori="values.lista_spessori"
            :lista_finiture="values.lista_finiture"
            :lista_formati="values.lista_formati"
            :spessore="decorativoConsociata.spessore_std"
            :finitura="decorativoConsociata.finitura_std"
            :formato="decorativoConsociata.formato_std"
            @update="onUpdateConsociata(this, $event)"
            @delete="onDeleteConsociata($event)"
          ></Standard>
          <NewStandard
            v-if="decorativo"
            :id="decorativo.id"
            :name="decorativo.name"
            :collection_id="decorativo.collection_id"
            :collection_name="decorativo.collection_name"
            :default_formato="decorativo.formato_std"
            :default_spessore="decorativo.spessore_std"
            :default_finitura="decorativo.finitura_std"
            :lista_spessori="values.lista_spessori"
            :lista_finiture="values.lista_finiture"
            :lista_formati="values.lista_formati"
            :note="decorativo.note"
            :reference_id="decorativo._id"
            @update="onAddConsociata($event)"
          ></NewStandard>
        </div>

        <!-- Sezione dati producibilità -->
        <div class="wk_accordion">
          <div class="wk_accordion_header" @click="toggleProd">
            <b>DATI PRODUCIBILITÀ</b>
            <v-icon
              v-if="!prodIsVisible"
              icon="mdi-triangle-small-down"
            ></v-icon>
            <v-icon v-if="prodIsVisible" icon="mdi-triangle-small-up"></v-icon>
          </div>

          <v-divider :thickness="2" class="border-opacity-100"></v-divider>

          <v-expand-transition>
            <DataComponent
              v-if="prodIsVisible"
              :key="decorativo._id"
              :_id="decorativo._id"
              :lista_spessori="decorativo.lista_spessori"
              :lista_finiture="decorativo.lista_finiture"
              :lista_formati="decorativo.lista_formati"
              :new_lista_spessori="decorativo.new_lista_spessori"
              :new_lista_finiture="decorativo.new_lista_finiture"
              :new_lista_formati="decorativo.new_lista_formati"
              @duplicate="onUpdate($event)"
              @update="onUpdate($event)"
              @restore="onRestore"
            ></DataComponent>
          </v-expand-transition>
        </div>

        <!-- Sezione nascondi in altre consociate -->
        <div class="wk_accordion">
          <div class="wk_accordion_header" @click="toggleConsociate">
            <b>NASCONDI IN ALTRE CONSOCIATE</b>
            <v-icon
              v-if="!consociateAreVisible"
              icon="mdi-triangle-small-down"
            ></v-icon>
            <v-icon
              v-if="consociateAreVisible"
              icon="mdi-triangle-small-up"
            ></v-icon>
          </div>

          <v-divider :thickness="2" class="border-opacity-100"></v-divider>

          <v-expand-transition>
            <ConsociateComponent
              v-if="consociateAreVisible"
              :id="decorativo._id"
              :consociate="countries"
              :hiddencons="decorativo.consociate_nascoste"
              @update="onUpdateHiddenCons($event)"
            ></ConsociateComponent>
          </v-expand-transition>
        </div>

        <!-- Design e Colori -->
        <div class="wk_accordion">
          <div class="wk_accordion_header" @click="toggleDesign">
            <b>DESIGN E COLORI*</b>
            <span class="wk_accordion_message"
              >*opzioni globali decorativo, non relativo alla sola
              collezione</span
            >
          </div>

          <v-divider :thickness="2" class="border-opacity-100"></v-divider>

          <v-expand-transition>
            <!--            <DesignComponent-->
            <!--              v-if="designAreVisible"-->
            <!--              :_id="decorativo._id"-->
            <!--              :designer="decorativo.design_edition"-->
            <!--              :lista_designer="lista_designer"-->
            <!--            ></DesignComponent>-->
          </v-expand-transition>
        </div>
      </div>
    </div>
  </div>
  <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="snackbar = false"
        >Chiudi
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>
.wk_breadcrumbs {
  font-size: 16px;
  margin: 30px 0;
  width: 100%;
  display: block;
  flex-shrink: 0;
}

.wk_page_title {
  margin-top: 30px;
  font-weight: 600;
}

.wk-change-collection {
  align-self: flex-end;

  .wk-change-collection__select {
    min-width: 200px;
  }
}

.wk_collezioni_view_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.wk_std_consociate {
  margin: 0 0 3em 0;
}

.wk_std_consociate_header {
  width: calc(25% - 16px) !important;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.wk_accordion {
  width: 100%;
  margin: 0 0 4em 0;
}

.wk_accordion_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > b {
    display: inline-block;
  }
}

.wk_accordion_message {
  font-size: 12px;
}
</style>

<script>
import axios from "axios";
import Decorativo from "@/components/DecorativoComponent.vue";
import {
  API_URL,
  countries,
  defaultTimeout,
  notifyLoading,
} from "@/utils/const";
import { countryName } from "@/utils/helper";
import DataComponent from "@/components/DataComponent.vue";
import ConsociateComponent from "@/components/ConsociateComponent.vue";
import authHeader from "@/services/data.service";
import { mapGetters } from "vuex";
// import DesignComponent from "@/components/DesignComponent.vue";

export default {
  components: {
    ConsociateComponent,
    DataComponent,
    Decorativo,
    // DesignComponent,
  },
  data() {
    return {
      loading: false,
      open: false,
      selectedDecorativo: null,
      selectedCollezione: null,
      collezioniCorrelate: [],
      decorativo: [],
      decorativiConsociate: [],
      text: "",
      snackbar: false,
      prodIsVisible: false,
      consociateAreVisible: false,
      designAreVisible: false,
      lista_designer: [],
      values: {
        lista_spessori: [],
        lista_formati: [],
        lista_finiture: [],
      },
      breadcrumb: [],
    };
  },
  async created() {
    this.loading = true;
    this.countries = countries;
    this.selectedCollezione = this.pathCollezione;
    this.decorativoObjId = this.pathId;
    await this.getDecorativo(this.decorativoObjId);
    await this.getDecorativiCorrelati(this.decorativo.id);
    await this.getConsStandard(this.decorativo._id);
    this.initBreadcrump();

    // soluzione più efficente rispetto al :key in router-view
    // this.$watch(
    //   () => this.$route.params,
    //   async () => {
    //     this.selectedCollezione = this.pathCollezione;
    //     this.decorativoObjId = this.pathId;
    //     await this.getDecorativo(this.decorativoObjId);
    //     await this.getDecorativiCorrelati(this.decorativo.id);
    //     await this.getDecorativoFromCons(this.decorativo.id);
    //     console.log("reload");
    //   }
    // );
  },
  async mounted() {
    if (!this.lista_formati || !this.lista_spessori || !this.lista_finiture) {
      // caricamento di tutti gli standard disponibili tra i decori
      await this.getStandardLists();
      // caricamento di tutte le liste disponibili tra i decori
      await this.getLists();
    } else {
      this.values.lista_finiture = this.lista_finiture;
      this.values.lista_spessori = this.lista_spessori;
      this.value.lista_formati = this.lista_formati;
    }

    if (!this.decorativo) {
      this.$router.push({ path: "/" });
    }

    if (this.$route.meta.title && this.decorativo) {
      // document.title = this.$route.meta.title + this.decorativo.name;
    } else {
      document.title = "ABET Decorativo";
    }

    document.title = "ABET Decorativo";

    this.lista_designer = await this.getDesigners();

    this.loading = false;
  },
  computed: {
    pathCollezione() {
      return this.$route.params.collezione;
    },
    pathId() {
      return this.$route.params.decorativo;
    },
    ...mapGetters("decoro", ["lista_formati"]),
    ...mapGetters("decoro", ["lista_spessori"]),
    ...mapGetters("decoro", ["lista_finiture"]),
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    countryName,
    async getDecorativo(_id) {
      await axios
        .get(API_URL + `decorativi/item/${_id}`, { headers: authHeader() })
        .then((response) => {
          this.decorativo = response.data;
          this.text = notifyLoading;
          this.snackbar = true;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$router.push("/403");
          }
          // this.text = notifyError;
          // this.snackbar = true;
        });
    },
    async getDecorativiCorrelati(collectionId) {
      await axios
        .get(API_URL + `decorativi/correlati/${collectionId}`, {
          headers: authHeader(),
        })
        .then((response) => (this.collezioniCorrelate = response.data))
        .catch((error) =>
          console.log(
            "Error while getting the collections related to this decorativo",
            error
          )
        );
    },
    async getConsStandard(referenceId) {
      await axios
        .get(API_URL + `cons/decorativo/related/${referenceId}`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.data && response.data.status === "success") {
            this.decorativiConsociate = response.data.data;
          } else {
            this.decorativiConsociate = [];
          }
        })
        .catch((error) =>
          console.log("Error while getting decorativi from consociate", error)
        );
    },
    async getDesigners() {
      await axios
        .get(API_URL + "decorativi/designers/all", {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            this.lista_designer = response.data;
            console.log(this.lista_designer);
          } else {
            this.lista_designer = [];
          }
        })
        .catch((error) =>
          console.log("Error while getting designer from decorativi", error)
        );
    },
    onUpdateHiddenCons(hiddencons) {
      this.decorativo.consociate_nascoste = hiddencons;
    },
    onAddConsociata(newCons) {
      this.decorativiConsociate.push(newCons);
    },
    onUpdateConsociata() {
      // console.log(updated);
      // this.decorativiConsociate = this.decorativiConsociate.map(
      //   (consociata) => {
      //     if (consociata._id === updated._id) {
      //       consociata.formato_std = updated.formato_std;
      //       consociata.spessore_std = updated.spessore_std;
      //       consociata.finitura_std = updated.finitura_std;
      //     }
      //   }
      // );
    },
    onDeleteConsociata(id) {
      this.decorativiConsociate = this.decorativiConsociate.filter(
        (consociata) => consociata._id !== id
      );
    },
    onUpdateImage(fileName) {
      this.decorativo.collection_img = fileName;
    },
    onRestore() {
      this.decorativo.new_lista_spessori = [];
      this.decorativo.new_lista_finiture = [];
      this.decorativo.new_lista_formati = [];
    },
    onUpdate(decorativo) {
      this.decorativo.new_lista_spessori = [...decorativo.new_lista_spessori];
      this.decorativo.new_lista_finiture = [...decorativo.new_lista_finiture];
      this.decorativo.new_lista_formati = [...decorativo.new_lista_formati];
    },
    goToDecorativo(selectedCollezione, decorativo) {
      // this.selectedCollezione = this.selectedDecorativo.collection_name;
      // console.log(`Go to collezione: ${selectedCollezione}`);
      // console.log(`Go to decorativo: ${decorativo}`);
      this.$router.push({
        path: `/collections/${selectedCollezione}/${decorativo}`,
      });
    },
    toggleProd() {
      this.prodIsVisible = !this.prodIsVisible;
    },
    toggleConsociate() {
      this.consociateAreVisible = !this.consociateAreVisible;
    },
    toggleDesign() {
      this.designAreVisible = !this.designAreVisible;
    },
    async getStandardLists() {
      this.values.lista_spessori = [];
      await axios
        .get(API_URL + `decorativi/standard/spessori`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            this.values.lista_spessori = response.data;
          }
        })
        .catch(() => {});
      await axios
        .get(API_URL + `cons/decorativo/standard/spessori`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            this.values.lista_spessori = this.values.lista_spessori.concat(
              response.data
            );
          }
        })
        .catch(() => {});

      this.values.lista_formati = [];
      await axios
        .get(API_URL + `decorativi/standard/formati`, { headers: authHeader() })
        .then((response) => {
          if (response.data) {
            this.values.lista_formati = response.data;
          }
        })
        .catch(() => {});
      await axios
        .get(API_URL + `cons/decorativo/standard/formati`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            this.values.lista_formati = this.values.lista_formati.concat(
              response.data
            );
          }
        })
        .catch(() => {});

      this.values.lista_finiture = [];
      await axios
        .get(API_URL + `decorativi/standard/finiture`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            this.values.lista_finiture = response.data;
          }
        })
        .catch(() => {});
      await axios
        .get(API_URL + `cons/decorativo/standard/finiture`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            this.values.lista_finiture = this.values.lista_finiture.concat(
              response.data
            );
          }
        })
        .catch(() => {});
    },
    async getLists() {
      await axios
        .get(API_URL + "decorativi/list/spessori", { headers: authHeader() })
        .then((response) => {
          if (response.data.data && response.data.status === "success") {
            this.values.lista_spessori = this.values.lista_spessori.concat(
              response.data.data
            );
          }
        })
        .catch((error) =>
          console.log("Error while getting the list of spessori", error)
        );
      this.$store.dispatch("decoro/updateSpessori", this.values.lista_spessori);

      await axios
        .get(API_URL + "decorativi/list/formati", { headers: authHeader() })
        .then((response) => {
          if (response.data.data && response.data.status === "success") {
            this.values.lista_formati = this.values.lista_formati.concat(
              response.data.data
            );
          }
        })
        .catch((error) =>
          console.log("Error while getting the list of formati", error)
        );
      this.$store.dispatch("decoro/updateFormati", this.values.lista_formati);

      await axios
        .get(API_URL + "decorativi/list/finiture", { headers: authHeader() })
        .then((response) => {
          if (response.data.data && response.data.status === "success") {
            this.values.lista_finiture = this.values.lista_finiture.concat(
              response.data.data
            );
          }
        })
        .catch((error) =>
          console.log("Error while getting the list of finiture", error)
        );
      this.$store.dispatch("decoro/updateFiniture", this.values.lista_finiture);
    },
    displayDecorativoName(id, name) {
      return `${id} - ${name}`;
    },
    displayDecorativoSpecs(
      collection_id,
      collection_name,
      spessore,
      formato,
      finitura
    ) {
      return `${collection_id} - ${collection_name}\n${spessore} - ${formato} - ${finitura}`;
    },
    getImgSrc(id) {
      return `https://abetlaminati.com/CAMPIONI/${id}_S.jpg`;
    },
    initBreadcrump() {
      this.breadcrumb = [
        {
          title: "collections",
          disabled: false,
          href: "/collections",
        },
        {
          title: this.selectedCollezione,
          disabled: false,
          href: `/collections/${this.selectedCollezione}`,
        },
        {
          title: `${this.decorativo.id} ${this.decorativo.name}`,
          disabled: true,
          href: "",
        },
      ];
    },
  },
};
</script>
