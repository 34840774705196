<script>
import {
  API_URL,
  defaultTimeout,
  notifyError,
  notifySaving,
} from "@/utils/const";
import axios from "axios";
import authHeader from "@/services/data.service";

export default {
  name: "decorativo-component",
  emits: ["update"],
  props: {
    _id: { required: true, type: String },
    id: { required: true, type: Number },
    name: { required: true, type: String },
    collezione: { required: true, type: String },
    extra_img: { required: true, type: String },
  },
  data() {
    return {
      fileName: null,
      adding: false,
      text: "",
      snackbar: false,
      dialog: false,
    };
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    disableAdding() {
      this.adding = false;
      this.clearFields();
    },
    enableAdding() {
      this.adding = true;
    },
    enableDialog() {
      this.dialog = true;
    },
    disableDialog() {
      this.dialog = false;
    },
    async newImage() {
      if (!this.fileName) {
        this.text = notifyError;
        this.snackbar = true;
      } else {
        const body = {
          _id: this._id,
          collection_img: this.fileName,
        };
        await axios
          .put(API_URL + "decorativi/image", body, { headers: authHeader() })
          .then((response) => {
            if (response.data) {
              this.$emit("update", this.fileName);
            }
            this.text = notifySaving;
            this.snackbar = true;
          })
          .catch(() => {
            this.text = notifyError;
            this.snackbar = true;
          });
      }
      this.adding = false;
      this.clearFields();
    },
    async restoreImage() {
      const body = {
        _id: this._id,
      };
      await axios
        .put(API_URL + "decorativi/image/restore", body, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            this.fileName = "";
            this.$emit("update", this.fileName);
          }
          this.text = notifySaving;
          this.snackbar = true;
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    clearFields() {
      this.fileName = null;
    },
  },
};
</script>

<template>
  <div class="wk-decorativo-title font-weight-bold mt-2">
    {{ id }} {{ name }}
  </div>
  <div class="wk-decorativo-subtitle">collezione: {{ collezione }}</div>
  <div class="wk_color_img">
    <div
      class="wk_color_img_bg wk_color_img_bg__collection"
      v-if="extra_img"
      :style="
        'background-image: url(https://abetlaminati.com/CAMPIONI/' +
        extra_img +
        '.jpg)'
      "
    ></div>
    <div
      class="wk_color_img_bg"
      v-else
      :style="
        'background-image: url(https://abetlaminati.com/CAMPIONI/' +
        id +
        '_S.jpg)'
      "
    ></div>
  </div>
  <div class="wk_extra_img_panel">
    <v-btn
      v-if="extra_img"
      variant="plain"
      size="regular"
      color="black"
      class="wk_remove_image_btn"
      @click="enableDialog"
    >
      <template v-slot:prepend>
        <div class="wk_image_avatar">
          <v-avatar
            :image="'https://abetlaminati.com/CAMPIONI/' + id + '_S.jpg'"
          ></v-avatar>
          <v-icon
            class="wk_image_avatar__icon"
            icon="mdi-refresh"
            size="large"
          ></v-icon>
        </div>
      </template>

      <v-dialog v-model="dialog" width="auto">
        <v-card>
          <v-card-text> Confermi di voler ripristinare l'immagine?</v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="
                disableDialog();
                restoreImage(_id);
              "
              block
              >Conferma
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      Ripristina immagine standard
    </v-btn>

    <v-btn
      v-if="!adding & !extra_img"
      prepend-icon="mdi-plus-circle-outline"
      variant="plain"
      size="regular"
      color="black"
      class="wk_add_image_btn"
      @click="enableAdding"
    >
      Aggiungi variante immagine
    </v-btn>

    <v-fade-transition>
      <div v-if="adding">
        <v-text-field label="Nome file" v-model="fileName"></v-text-field>
        <v-btn
          prepend-icon="mdi-check-circle-outline"
          variant="plain"
          size="regular"
          color="black"
          class="wk_add_image_btn"
          @click="newImage"
        ></v-btn>
        <v-btn
          prepend-icon="mdi-close-circle-outline"
          variant="plain"
          size="regular"
          color="black"
          class="wk_add_image_btn"
          @click="disableAdding"
        ></v-btn>
        <!-- todo fix class -->
      </div>
    </v-fade-transition>
  </div>

  <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="snackbar = false"
        >Chiudi
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>
.wk-decorativo-title,
.wk-decorativo-subtitle {
  font-size: 20px;
}

.wk_color_img {
  padding: 0;
  margin: 20px;
  margin: 40px 0;
}

.wk_color_img_bg {
  width: 200px;
  height: 286px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.wk_add_image_btn {
  opacity: 1;
}

.wk_add_image_btn >>> .v-btn__content,
.wk_remove_image_btn >>> .v-btn__content {
  text-transform: none;
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: normal;
}

.wk_image_avatar {
  position: relative;
}

.wk_image_avatar__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
