import { NavigationClient } from "@azure/msal-browser";

export class VueNavigationClient extends NavigationClient {
  constructor(router) {
    super();
    this.router = router;
  }

  navigateExternal(url, options) {
    return super.navigateExternal(url, options);
  }

  async navigateInternal(url, options) {
    const path = url.replace(location.origin, "");
    options.noHistory
      ? await this.router.replace(path)
      : await this.router.push(path);
    return true;
  }
}
