<template>
  <div class="wk_collezioni_view wrapper">
    <div class="d-flex flex-column w-100 mx-2">
      <div id="collezione-select-bar" class="d-flex align-center">
        <div class="d-flex align-center w-33">
          <span class="wk_lista-collezioni_label">Select a collection</span>
          <v-autocomplete
            :items="listaCollezioni"
            v-model="selectedCollezione"
            class="mx-3 my-0"
            auto-select-first
            clearable
            @update:model-value="goToCollezione(selectedCollezione)"
          ></v-autocomplete>
        </div>
      </div>

      <v-text-field v-if="loading">Loading...</v-text-field>

      <DataTable
        :value="collezioni"
        :reorderable-columns="true"
        @rowReorder="onRowReorder"
        v-model:editingRows="editingRows"
        editMode="row"
        @row-edit-save="onRowEditSave"
        class="p-datatable-sm"
        lazy
        resizableColumns
        columnResizeMode="expand"
      >
        <Column
          v-if="isAdmin"
          field="index"
          rowReorder
          headerStyle="width: 6rem"
          :reorderableColumn="false"
        />
        <Column field="nome" header="Nome">
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>
        <Column field="codice_1" header="Codice collezione">
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>
        <Column field="totale_decorativi" header="Totale decorativi"></Column>
        <Column
          field="immagini_decorativi"
          header="immagini decorativi"
          dataType="boolean"
          style="width: 50px; min-width: 50px; min-height: 40px; height: 50px"
        >
          <template #body="{ data }">
            <i
              class="pi"
              :class="{
                'pi-check-circle text-green-500': data.verified,
                'pi-times-circle text-red-400': !data.verified,
              }"
            ></i>
          </template>
        </Column>
        <Column
          v-if="isAdmin"
          field="action_edit"
          :rowEditor="true"
          style="width: 50px; min-width: 8rem"
          bodyStyle="text-align:right"
        >
        </Column>
      </DataTable>
    </div>

    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"
          >Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
/*@import "primevue/resources/themes/lara-light-green/theme.css";*/

/* @import 'primevue/resources/primevue.min.css';
@import 'primeicons/primeicons.css';
@import '../../public/themes/md-light-indigo/theme.css'; */

/* @import 'primevue/resources/themes/saga-blue/theme.css';*/

.wk_collezioni_view {
  font-size: 20px;
  text-transform: lowercase;
}

#collezione-select-bar {
  margin: 30px 0;
  width: 100%;
}
</style>

<script>
import axios from "axios";
import {
  API_URL,
  notifyError,
  notifyLoading,
  notifySaving,
} from "@/utils/const";
import authHeader from "@/services/data.service";

export default {
  data() {
    return {
      loading: false,
      // data from APIs
      collezioni: null,
      listaCollezioni: [],
      selectedCollezione: null,
      editingRows: [],
      text: "",
      snackbar: false,
      isAdmin: false,
    };
  },
  mounted() {
    if (this.$route.meta.title) {
      document.title = this.$route.meta.title;
    }

    this.loading = true;
    this.getRole();
    this.getCollezioni();
    this.loading = false;
  },
  methods: {
    async getRole() {
      await axios
        .get(API_URL + "auth", {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.data) {
            if (
              response.data.data.role === "admin" &&
              response.data.data.cons === "all"
            ) {
              this.isAdmin = true;
            }
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async getCollezioni() {
      await axios
        .get(API_URL + "collezioni/all", { headers: authHeader() })
        .then((response) => {
          if (response.data) {
            this.collezioni = response.data;
            this.listaCollezioni = this.collezioni.map((item) => item.nome);
            this.text = notifyLoading;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async updateIndex(id, index) {
      const body = { _id: id, index: index };
      await axios
        .put(API_URL + "collezioni/reorder", body, { headers: authHeader() })
        .then(() => {
          this.text = notifySaving;
          this.snackbar = true;
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    goToCollezione(selectedCollezione) {
      this.$router.push({ path: `/collections/${selectedCollezione}` });
    },
    onRowReorder(event) {
      this.collezioni = event.value;
      for (let i = 0; i < this.collezioni.length; i++) {
        if (i !== this.collezioni[i].index) {
          this.updateIndex(this.collezioni[i]._id, i);
          this.collezioni[i].index = i;
          console.log(
            `${this.collezioni[i].nome} has index: ${this.collezioni[i].index}`
          );
        }
      }
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
        case "nome":
        case "codice_1":
        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      this.collezioni[index] = newData;
    },
  },
};
</script>
