<script>
import {
  API_URL,
  defaultTimeout,
  duplicateConfirmation,
  notifyError,
  notifySaving,
  restoreConfirmation,
} from "@/utils/const";
import axios from "axios";
import authHeader from "@/services/data.service";

export default {
  name: "data-component",
  emits: ["duplicate", "restore", "update"],
  data() {
    return {
      newData: false,
      closableSpessori: false,
      closableFiniture: false,
      closableFormati: false,
      dialog: false,
      restoring: false,
      duplicating: false,
      tmp_lista_spessori: [],
      tmp_lista_finiture: [],
      tmp_lista_formati: [],
      newSpessore: null,
      newFinitura: null,
      newFormato: null,
      text: "",
      snackbar: false,
    };
  },
  props: {
    _id: { required: true, type: String },
    lista_spessori: { required: true, type: Array },
    lista_finiture: { required: true, type: Array },
    lista_formati: { required: true, type: Array },
    new_lista_spessori: { required: false, type: Array },
    new_lista_finiture: { required: false, type: Array },
    new_lista_formati: { required: false, type: Array },
  },
  created() {
    if (this.new_lista_spessori) {
      this.tmp_lista_spessori = [...this.new_lista_spessori];
    }

    if (this.new_lista_formati) {
      this.tmp_lista_formati = [...this.new_lista_formati];
    }

    if (this.new_lista_finiture) {
      this.tmp_lista_finiture = [...this.new_lista_finiture];
    }
  },
  computed: {
    duplicated() {
      if (
        this.lista_spessori.length === 0 &&
        this.lista_finiture.length === 0 &&
        this.lista_formati.length === 0
      ) {
        return true;
      }
      return (
        this.new_lista_spessori.length > 0 ||
        this.new_lista_formati.length > 0 ||
        this.new_lista_finiture.length > 0
      );
    },
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    duplicateConfirmation() {
      return duplicateConfirmation;
    },
    restoreConfirmation() {
      return restoreConfirmation;
    },
    toggleNewData() {
      this.newData = !this.newData;
    },
    // funzioni che gestiscono il salvataggio e annullamento delle modifiche degli spessori
    editSpessori() {
      this.closableSpessori = true;
    },
    confirmSpessori() {
      this.updateSpessori();
      this.closableSpessori = false;
    },
    cancelSpessori() {
      this.tmp_lista_spessori = this.new_lista_spessori
        ? [...this.new_lista_spessori]
        : [];
      this.closableSpessori = false;
    },
    removeSpessore(spessore) {
      let index = this.tmp_lista_spessori.indexOf(spessore);
      if (index !== -1) {
        this.tmp_lista_spessori.splice(index, 1);
      }
    },
    addSpessore(spessore) {
      spessore = parseFloat(spessore);

      if (!spessore) {
        this.text = "Inserisci uno spessore valido";
        this.snackbar = true;
        this.newSpessore = null;
        return;
      }

      if (this.tmp_lista_spessori.includes(spessore)) {
        this.text = "Spessore già presente";
        this.snackbar = true;
        this.newSpessore = null;
        return;
      }

      this.tmp_lista_spessori.push(spessore);
      this.newSpessore = null;
    },
    // funzioni che gestiscono il salvataggio e annullamento delle modifiche delle finiture
    editFiniture() {
      this.closableFiniture = true;
    },
    confirmFiniture() {
      this.updateFiniture();
      this.closableFiniture = false;
    },
    cancelFiniture() {
      this.tmp_lista_finiture = this.new_lista_finiture
        ? [...this.new_lista_finiture]
        : [];
      this.closableFiniture = false;
    },
    removeFinitura(finitura) {
      let index = this.tmp_lista_finiture.indexOf(finitura);
      if (index !== -1) {
        this.tmp_lista_finiture.splice(index, 1);
      }
    },
    addFinitura(finitura) {
      if (!finitura) {
        this.text = "Inserisci una finitura valida.";
        this.snackbar = true;
        this.newFinitura = null;
        return;
      }

      if (this.tmp_lista_finiture.includes(finitura)) {
        this.text = "Finitura già presente";
        this.snackbar = true;
        this.newFinitura = null;
        return;
      }

      this.tmp_lista_finiture.push(finitura);
      this.newFinitura = null;
    },
    // funzioni che gestiscono il salvataggio e annullamento delle modifiche dei formati
    editFormati() {
      this.closableFormati = true;
    },
    confirmFormati() {
      this.updateFormati();
      this.closableFormati = false;
    },
    cancelFormati() {
      this.tmp_lista_formati = this.new_lista_formati
        ? [...this.new_lista_formati]
        : [];
      this.closableFormati = false;
    },
    removeFormati(formato) {
      let index = this.tmp_lista_formati.indexOf(formato);
      if (index !== -1) {
        this.tmp_lista_formati.splice(index, 1);
      }
    },
    addFormato(formato) {
      const regex = /^\d+X\d+$/;

      if (!formato) {
        this.text = "Inserisci un formato valido";
        this.snackbar = true;
        this.newFormato = null;
        return;
      }

      if (!regex.test(formato)) {
        this.text =
          "Il formato deve essere del tipo Lunghezza X Larghezza (esempio: 400X100)";
        this.snackbar = true;
        this.newFormato = null;
        return;
      }

      if (this.tmp_lista_formati.includes(formato)) {
        this.text = "Formato già presente";
        this.snackbar = true;
        this.newFormato = null;
        return;
      }

      this.tmp_lista_formati.push(formato);
      this.newFormato = null;
    },
    async duplicate() {
      await axios
        .put(
          API_URL + "decorativi/duplicate",
          {
            _id: this._id,
          },
          { headers: authHeader() }
        )
        .then((response) => {
          if (response.data) {
            this.$emit("duplicate", response.data);
            this.tmp_lista_finiture = response.data.lista_finiture;
            this.tmp_lista_spessori = response.data.lista_spessori;
            this.tmp_lista_formati = response.data.lista_formati;
            this.text = notifySaving;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async restore() {
      await axios
        .put(
          API_URL + "decorativi/restore",
          {
            _id: this._id,
          },
          { headers: authHeader() }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$emit("restore");
            this.tmp_lista_finiture = [];
            this.tmp_lista_spessori = [];
            this.tmp_lista_formati = [];
            this.text = notifySaving;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async updateSpessori() {
      await axios
        .put(
          API_URL + "decorativi/list",
          {
            _id: this._id,
            new_lista_spessori: this.tmp_lista_spessori,
          },
          { headers: authHeader() }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$emit("update", response.data.data);
            this.text = notifySaving;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async updateFormati() {
      await axios
        .put(
          API_URL + "decorativi/list",
          {
            _id: this._id,
            new_lista_formati: this.tmp_lista_formati,
          },
          { headers: authHeader() }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$emit("update", response.data.data);
            this.text = notifySaving;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async updateFiniture() {
      await axios
        .put(
          API_URL + "decorativi/list",
          {
            _id: this._id,
            new_lista_finiture: this.tmp_lista_finiture,
          },
          { headers: authHeader() }
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.$emit("update", response.data.data);
            this.text = notifySaving;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
  },
};
</script>

<template>
  <div class="wk-data-component">
    <div class="wk_dati_producibilita">
      <div class="wk_dati_prod_col mx-2">
        <div class="wk_dati_prod_label">Spessore (mm)</div>

        <div class="wk_dati_prod_data">
          <span
            v-for="(spessore, index) in lista_spessori"
            :key="index"
            class="text-grey"
          >
            {{ spessore }}
            <span v-if="index < lista_spessori.length - 1"> - </span>
          </span>
          <span v-if="lista_spessori.length === 0">
            Spessori non disponibili per questo decoro.
          </span>
        </div>
      </div>

      <div class="wk_dati_prod_col mx-2">
        <div class="wk_dati_prod_label">Formato (cm)</div>
        <div class="wk_dati_prod_data">
          <span
            v-for="(formato, index) in lista_formati"
            :key="index"
            class="text-grey"
          >
            {{ formato }}
            <span v-if="index < lista_formati.length - 1"> - </span>
          </span>
          <span v-if="lista_formati.length === 0">
            Formati non disponibili per questo decoro.
          </span>
        </div>
      </div>

      <div class="wk_dati_prod_col mx-2">
        <div>
          <v-icon
            icon="mdi-pencil-plus"
            class="edit-icon"
            v-if="!duplicated"
            @click="
              restoring = false;
              dialog = true;
            "
          ></v-icon>
          <v-icon
            v-else
            icon="mdi-close-circle-outline"
            class="edit-icon"
            @click="
              restoring = true;
              dialog = true;
            "
          ></v-icon>
        </div>
        <div class="wk_dati_prod_label">Finitura</div>
        <div class="wk_dati_prod_data">
          <span
            v-for="(finitura, index) in lista_finiture"
            :key="index"
            class="text-grey"
          >
            {{ finitura }}
            <span v-if="index < lista_finiture.length - 1"> - </span>
          </span>
          <span v-if="lista_finiture.length === 0">
            Finiture non disponibili per questo decoro.
          </span>
        </div>
      </div>
    </div>

    <v-progress-circular indeterminate v-if="duplicating"></v-progress-circular>

    <v-expand-transition>
      <div class="wk_nuovi_dati_producibilita" v-if="duplicated">
        <div class="wk_dati_prod_col mx-2">
          <!-- icona che abilita la modifica degli spessori -->
          <v-icon
            v-if="!closableSpessori"
            icon="mdi-pencil-plus"
            class="edit-icon"
            @click="editSpessori"
          ></v-icon>
          <!-- icona che permette di confermare gli spessori visualizzati a schermo -->
          <v-icon
            v-if="closableSpessori"
            class="edit-icon"
            icon="mdi-close-circle-outline"
            @click="cancelSpessori"
          ></v-icon>
          <v-icon
            v-if="closableSpessori"
            class="edit-icon"
            icon="mdi-check-circle-outline"
            @click="confirmSpessori"
          ></v-icon>
          <div class="wk_dati_prod_label">Spessore (mm)</div>
          <div class="wk_dati_prod_data">
            <div v-if="!closableSpessori">
              <span
                v-for="(spessore, index) in tmp_lista_spessori"
                :key="index"
                class="text-grey"
              >
                {{ spessore }}
                <span v-if="index < tmp_lista_spessori.length - 1"> - </span>
              </span>
            </div>
            <div v-else>
              <v-chip
                v-for="(spessore, index) in tmp_lista_spessori"
                :key="index"
                class="wk-prod-chip rounded-pill ma-1"
                variant="outlined"
                :closable="closableSpessori"
                @click:close="removeSpessore(spessore)"
              >
                {{ spessore }}
              </v-chip>
              <v-text-field
                v-model="newSpessore"
                label="Spessori"
                variant="outlined"
                rounded="rounded"
                class="wk-prod-chip mt-3"
                append-inner-icon="mdi-check-circle-outline"
                @click:append-inner="addSpessore(newSpessore)"
                @keyup.enter="addSpessore(newSpessore)"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="wk_dati_prod_col mx-2">
          <v-icon
            v-if="!closableFormati"
            icon="mdi-pencil-plus"
            class="edit-icon"
            @click="editFormati"
          ></v-icon>
          <v-icon
            v-if="closableFormati"
            class="edit-icon"
            icon="mdi-close-circle-outline"
            @click="cancelFormati"
          ></v-icon>
          <v-icon
            v-if="closableFormati"
            class="edit-icon"
            icon="mdi-check-circle-outline"
            @click="confirmFormati"
          ></v-icon>
          <div class="wk_dati_prod_label">Formato (cm)</div>
          <div class="wk_dati_prod_data">
            <div v-if="!closableFormati">
              <span
                v-for="(formato, index) in tmp_lista_formati"
                :key="index"
                class="text-grey"
              >
                {{ formato }}
                <span v-if="index < tmp_lista_formati.length - 1"> - </span>
              </span>
            </div>
            <div v-else>
              <v-chip
                v-for="(formato, index) in tmp_lista_formati"
                :key="index"
                class="wk-prod-chip rounded-pill ma-1"
                variant="outlined"
                :closable="closableFormati"
                @click:close="removeFormati(formato)"
              >
                {{ formato }}
              </v-chip>
              <v-text-field
                v-model="newFormato"
                label="Formato"
                variant="outlined"
                rounded="rounded"
                class="wk-prod-chip mt-3"
                append-inner-icon="mdi-check-circle-outline"
                @click:append-inner="addFormato(newFormato)"
                @keyup.enter="addFormato(newFormato)"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="wk_dati_prod_col mx-2">
          <v-icon
            v-if="!closableFiniture"
            icon="mdi-pencil-plus"
            class="edit-icon"
            @click="editFiniture"
          ></v-icon>
          <v-icon
            v-if="closableFiniture"
            class="edit-icon"
            icon="mdi-close-circle-outline"
            @click="cancelFiniture"
          ></v-icon>
          <v-icon
            v-if="closableFiniture"
            class="edit-icon"
            icon="mdi-check-circle-outline"
            @click="confirmFiniture"
          ></v-icon>
          <div class="wk_dati_prod_label">Finitura</div>
          <div class="wk_dati_prod_data">
            <div v-if="!closableFiniture">
              <span
                v-for="(finitura, index) in tmp_lista_finiture"
                :key="index"
                class="text-grey"
              >
                {{ finitura }}
                <span v-if="index < tmp_lista_finiture.length - 1"> - </span>
              </span>
            </div>
            <div v-else>
              <v-chip
                v-for="(finitura, index) in tmp_lista_finiture"
                :key="index"
                class="wk-prod-chip rounded-pill ma-1"
                variant="outlined"
                :closable="closableFiniture"
                @click:close="removeFinitura(finitura)"
              >
                {{ finitura }}
              </v-chip>
              <v-text-field
                v-model="newFinitura"
                label="Finitura"
                variant="outlined"
                rounded="rounded"
                class="wk-prod-chip mt-3"
                append-inner-icon="mdi-check-circle-outline"
                @click:append-inner="addFinitura(newFinitura)"
                @keyup.enter="addFinitura(newFinitura)"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </v-expand-transition>

    <v-dialog v-model="dialog" width="auto">
      <v-card
        max-width="500"
        prepend-icon="mdi-update"
        :text="restoring ? restoreConfirmation() : duplicateConfirmation()"
        :title="restoring ? 'Ripristino' : 'Duplica'"
      >
        <template v-slot:actions>
          <v-btn
            class="ms-auto"
            @click="
              dialog = false;
              restoring ? restore() : duplicate();
            "
            text="Conferma"
            block="true"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"
          >Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.wk_dati_producibilita,
.wk_nuovi_dati_producibilita {
  display: flex;
  justify-content: space-between;
}

.wk_dati_prod_col {
  margin-top: 25px;
  font-size: 14px;
  width: 33%;
}

.wk_dati_prod_label {
  font-weight: 600;
  margin-bottom: 20px;
}

.wk-prod-chip {
  color: grey;
  transition: color 0.3s ease;
}

.edit-icon {
  display: block;
  color: grey;
  margin: 4px;
  float: right;
}

.edit-icon:hover,
.wk-prod-chip:hover {
  color: black;
  cursor: pointer;
}

.wk-pills {
  background-color: #e9e9e9;
  border: 0;
  border-radius: 5px !important;
  height: 1.6em;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
}
</style>
