<template>
  <div class="home wrapper">
    <div class="wk_home_intro">
      <div class="wk_intro__row_left">
        <h1 class="wk_intro__title">Welcome in Abet Toolkit</h1>
      </div>
      <div class="wk_intro__row_right">
        <div class="wk_disclaimer"></div>
        <div class="wk_login_button_container">
          <button class="microsoft_button" @click="login">
            <img
              src="../../public/img/microsoft-logo.svg"
              alt="Microsoft Logo"
              class="microsoft_logo"
            />
            Sign in with Azure AD
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.wk_login_button_container {
  font-family: "Segoe UI", sans-serif;
  //font-family: "Arial", sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #5e5e5e;
  //font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  transition: color 0.3s ease;
}

.microsoft_button {
  display: flex;
  align-items: center;
  //background-color: #0078d4;
  background-color: #ffffff;
  padding: 10px 20px;
  //border-radius: 5px;
  cursor: pointer;
  border: 1px solid #8c8c8c;
  //border: none;
  transition: background-color 0.3s ease;
}

.microsoft_button:hover {
  color: #ffffff;
  background-color: #005a9e;
}

.microsoft_logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
}

.home.wrapper {
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
}

.wk_home_intro {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

h1.wk_intro__title {
  font-size: 40px;
  font-weight: 800;
}

.wk_intro__row_right,
.wk_intro__row_left {
  width: 50%;
}

.wk_intro__row_right {
  max-width: 500px;
}

.wk_heading_3 {
  color: black;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3em;
}
</style>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      user: {
        username: null,
        password: null,
      },
      visible: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;

      this.$store
        .dispatch("auth/login")
        .then((result) => {
          console.log(result);
          this.$router.push({ path: "/" });
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });

      // this.$store
      //   .dispatch("auth/login", this.user)
      //   .then(() => {
      //     this.$router.push({ path: "/" });
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //   });
    },
  },
  mounted() {
    if (this.$route.meta.title) {
      document.title = this.$route.meta.title;
    }
  },
};
</script>
