// import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: process.env.VUE_APP_TENANT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
    mainWindowsRedirectUri: process.env.VUE_APP_MAIN_WINDOW_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
  },

  system: {
    // loggerOptions: {
    //   logLevel: LogLevel.Verbose,
    //   loggerCallback(level, message, containsPii) {
    //     if (!containsPii) {
    //       const parts = message.split(" : ");
    //       const text = parts.pop();
    //       switch (level) {
    //         case LogLevel.Error:
    //           return console.error(text);
    //
    //         case LogLevel.Warning:
    //           return console.warn(text);
    //
    //         case LogLevel.Info:
    //           return console.info(text);
    //
    //         case LogLevel.Verbose:
    //           return console.debug(text);
    //       }
    //     }
    //   },
    // },
  },
};

export const loginRequest = {
  scopes: [process.env.VUE_APP_AZURE_API],
};
