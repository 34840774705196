<template>
  <div class="wk_colori_view wrapper">
    <div class="d-flex flex-column w-100 mx-2">
      <div class="d-flex align-center w-33 ma-3">
        <span class="wk_lista-colori_label">gestione filtro Colori</span>
      </div>

      <div class="text-center" v-if="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue"
          indeterminate
        ></v-progress-circular>
      </div>

      <DataTable
        v-else
        :value="colori"
        :reorderable-columns="true"
        @rowReorder="onRowReorder"
        v-model:editingRows="editingRows"
        editMode="row"
        @row-edit-save="onRowEditSave"
        class="p-datatable-sm"
        lazy
        resizableColumns
        columnResizeMode="expand"
      >
        <Column
          v-if="isAdmin"
          field="index"
          rowReorder
          headerStyle="width: 6rem"
          :reorderableColumn="false"
        />
        <Column field="hex" header="Colore">
          <template #body="{ data }">
            <div
              class="flex align-items-center gap-2 ma-1"
              :style="{
                backgroundColor: data.hex,
                borderRadius: '50%',
                width: '30px',
                paddingTop: '30px',
              }"
            ></div>
          </template>
          <template #editor="{ data, field }">
            <v-color-picker hide-inputs v-model="data[field]"></v-color-picker>
          </template>
        </Column>
        <Column field="colore" header="Colore">
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>
        <Column field="visibile" header="Visibilità filtro">
          <template #body="{ data }">
            <v-switch
              v-model="data.visibile"
              color="green"
              hide-details
              outset
              :disabled="!isAdmin"
            ></v-switch>
          </template>
        </Column>
        <Column
          field="action_edit"
          :rowEditor="isAdmin"
          style="width: 50px; min-width: 8rem"
          bodyStyle="text-align:right"
        ></Column>
      </DataTable>
    </div>

    <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"
          >Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.wk_lista-colori_label {
  font-size: 20px;
  width: 100%;
  margin: 50px 0;
}
</style>

<script>
import axios from "axios";
import {
  API_URL,
  defaultTimeout,
  notifyError,
  notifyLoading,
} from "@/utils/const";
import authHeader from "@/services/data.service";

export default {
  data() {
    return {
      loading: false,
      colori: null,
      editingRows: [],
      snackbar: false,
      text: "",
      isAdmin: false,
    };
  },
  async mounted() {
    if (this.$route.meta.title) {
      document.title = this.$route.meta.title;
    }

    this.getRole();

    this.loading = true;
    await this.getColori();
    this.loading = false;
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    async getColori() {
      await axios
        .get(API_URL + "colori/all", { headers: authHeader() })
        .then((response) => {
          if (response.data) {
            this.colori = response.data;
            this.text = notifyLoading;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    async getRole() {
      await axios
        .get(API_URL + "auth", {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.data) {
            this.isAdmin = response.data.data.role === "admin";
          }
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
    onRowReorder(event) {
      this.colori = event.value;
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      this.colori[index] = newData;
    },
  },
};
</script>
