import Store from "@/store";

export default function authHeader() {
  const token = Store.getters["auth/token"];

  if (token) {
    return { authorization: `Bearer ${token}` };
  }

  return {};
}
