<script>
export default {
  name: "design-component",
  data() {
    return {
      selectedDesigner: "",
      selectedCollezione: "",
      selectedColore: "",
    };
  },
  props: {
    _id: { required: true, type: String },
    designer: { required: true, type: String },
    lista_designer: { required: true, type: Array },
    // lista_collezioni: { required: true, type: Array },
    // lista_colori: { required: true, type: Array },
  },
  mounted() {
    this.selectedDesigner = this.designer;
  },
  methods: {},
};
</script>

<template>
  <div class="wk_dati_design">
    <div class="wk_dati_design_col w-33 mx-2">
      <div class="wk_dati_design_label">Designer</div>
      <v-autocomplete
        class="wk_field_selectbox"
        v-model="selectedDesigner"
        :items="lista_designer"
        clearable
      ></v-autocomplete>
    </div>
    <!--    <div class="wk_dati_design_col w-33 mx-2">-->
    <!--      <div class="wk_dati_design_label">Collezioni</div>-->
    <!--            <v-autocomplete-->
    <!--              class="wk_field_selectbox"-->
    <!--              :v-model="selectedCollezione"-->
    <!--              :items="lista_collezioni"-->
    <!--              auto-select-first-->
    <!--              hide-details-->
    <!--              clearable-->
    <!--              disabled-->
    <!--            ></v-autocomplete>-->
    <!--    </div>-->
    <!--    <div class="wk_dati_design_col w-33 mx-2">-->
    <!--      <div class="wk_dati_design_label">Filtro colore</div>-->
    <!--            <v-autocomplete-->
    <!--              class="wk_field_selectbox"-->
    <!--              :v-model="selectedColore"-->
    <!--              :items="lista_colori"-->
    <!--              auto-select-first-->
    <!--              hide-details-->
    <!--              clearable-->
    <!--              disabled-->
    <!--            ></v-autocomplete>-->
    <!--    </div>-->
  </div>
</template>

<style scoped>
.wk_dati_design {
  display: flex;
  justify-content: flex-end;
}

.wk_dati_design_col {
  margin-top: 25px;
  font-size: 14px;
}

.wk_dati_design_label {
  font-weight: 600;
  margin-bottom: 20px;
}

.v-input__details {
  display: none;
}
</style>
