const initialStock = localStorage.getItem("stock");

const initialState = initialStock
  ? { stock: initialStock }
  : {
      stock: {
        magazzini: null,
      },
    };

export const stock = {
  namespaced: true,
  state: initialState,
  actions: {
    updateMagazzini(context, magazziniList) {
      context.commit("updatedMagazzini", magazziniList);
    },
  },
  mutations: {
    updatedMagazzini(state, magazziniList) {
      state.stock.magazzini = magazziniList;
    },
  },
  getters: {
    magazzini(state) {
      return state.stock.magazzini;
    },
  },
};
