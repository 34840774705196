<script>
import {
  API_URL,
  countries,
  defaultTimeout,
  notifyConsAlreadyExist,
  notifyError,
  notifySaving,
} from "@/utils/const";
import axios from "axios";
import authHeader from "@/services/data.service";
import { countryName } from "@/utils/helper";

export default {
  name: "new-standard-component",
  emits: ["update"],
  props: {
    id: { required: true, type: Number },
    name: { required: true, type: String },
    collection_id: { required: true, type: String },
    collection_name: { required: true, type: String },
    default_spessore: { required: false, type: Number },
    default_finitura: { required: false, type: String },
    default_formato: { required: false, type: String },
    lista_spessori: { required: true, type: Array },
    lista_finiture: { required: true, type: Array },
    lista_formati: { required: true, type: Array },
    note: { required: true, type: String },
    reference_id: { required: true, type: String },
  },
  data() {
    return {
      adding: false,
      selectedSpessore: null,
      selectedFormato: null,
      selectedFinitura: null,
      selectedNazione: null,
      text: "",
      snackbar: false,
    };
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    countryName,
    countries() {
      return countries;
    },
    async createNewDecorativo() {
      const body = {
        id: this.id,
        name: this.name,
        collection_id: this.collection_id,
        collection_name: this.collection_name,
        nazione: this.selectedNazione,
        finitura_std: this.selectedFinitura,
        spessore_std: this.selectedSpessore,
        formato_std: this.selectedFormato,
        lunghezza_std: 0,
        larghezza_std: 0,
        note: this.note,
        reference_id: this.reference_id,
      };
      await axios
        .post(API_URL + `cons/decorativo/new`, body, { headers: authHeader() })
        .then((response) => {
          if (response.data.data && response.data.status === "success") {
            this.$emit("update", response.data.data);
            this.text = notifySaving;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            this.text = notifyConsAlreadyExist;
          } else {
            this.text = notifyError;
          }
          this.snackbar = true;
        });
      this.adding = false;
      this.clearFields();
    },
    enableAdding() {
      this.adding = true;
      this.selectedSpessore = this.default_spessore
        ? this.default_spessore
        : null;
      this.selectedFormato = this.default_formato ? this.default_formato : null;
      this.selectedFinitura = this.default_finitura
        ? this.default_finitura
        : null;
    },
    disableAdding() {
      this.adding = false;
      this.clearFields();
    },
    clearFields() {
      this.selectedFormato = null;
      this.selectedSpessore = null;
      this.selectedFinitura = null;
      this.selectedNazione = null;
    },
  },
};
</script>

<template>
  <v-fade-transition>
    <div class="wk_new_standard" v-if="adding">
      <!-- Adding component -->
      <div class="w-25 mx-2 align-self-center">
        <v-select
          label="Consociata"
          :items="countries()"
          v-model="selectedNazione"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="countryName(item.raw)"
            ></v-list-item>
          </template>
        </v-select>
      </div>
      <v-combobox
        v-model="selectedSpessore"
        :items="lista_spessori"
        class="mx-2 my-0 w-25"
        auto-select-first
        clearable
      ></v-combobox>
      <v-combobox
        v-model="selectedFormato"
        :items="lista_formati"
        class="mx-2 my-0 w-25"
        auto-select-first
        clearable
      ></v-combobox>
      <v-combobox
        v-model="selectedFinitura"
        :items="lista_finiture"
        class="mx-2 my-0 w-25"
        auto-select-first
        clearable
      ></v-combobox>

      <v-btn
        prepend-icon="mdi-check-circle-outline"
        variant="plain"
        size="regular"
        color="black"
        class="wk_new_standard_confirm"
        @click="createNewDecorativo"
      ></v-btn>
      <v-btn
        prepend-icon="mdi-close-circle-outline"
        variant="plain"
        size="regular"
        color="black"
        class="wk_new_standard_delete"
        @click="disableAdding"
      ></v-btn>
    </div>
  </v-fade-transition>

  <!-- Control component -->
  <div
    class="d-flex flex-row w-100"
    :style="adding ? 'opacity: 20%;' : 'opacity: 100%;'"
  >
    <div class="w-100 mx-2 align-self-center">
      <v-btn
        icon="mdi-plus-circle-outline"
        density="compact"
        variant="text"
        color="black"
        @click="enableAdding"
      ></v-btn>
      <span class="mx-2">Aggiungi standard</span>
    </div>
    <v-autocomplete
      v-for="index in 3"
      :key="index"
      class="mx-2 my-0 w-100"
      disabled
    ></v-autocomplete>
  </div>

  <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="snackbar = false"
        >Chiudi
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>
.wk_new_standard {
  display: flex;
  align-items: center;
  position: relative;
}

.wk_new_standard_confirm {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateX(100%) translateY(-50%);
  width: 30px;
  height: 30px;
}

.wk_new_standard_delete {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateX(100%) translateY(-50%);
  width: 30px;
  height: 30px;
}
</style>
