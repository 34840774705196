<script>
export default {
  name: "Forbidden",
};
</script>

<template>
  <div class="w-100 d-flex justify-center">
    <v-empty-state
      class="w-100"
      headline="403"
      title="Forbidden"
      text="Access to this resource on the server is denied."
      image="/img/logo_abet.svg"
      max-width="500px"
    ></v-empty-state>
  </div>
</template>

<style scoped></style>
