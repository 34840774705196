const initialProd = localStorage.getItem("prod");

const initialState = initialProd
  ? { prod: initialProd }
  : {
      prod: {
        tipi: null,
        colori1: null,
        colori2: null,
        finiture1: null,
        finiture2: null,
        spessori: null,
        lunghezze: null,
        larghezze: null,
        collezioni: null,
      },
    };

export const prod = {
  namespaced: true,
  state: initialState,
  actions: {
    updateTipi(context, tipiList) {
      context.commit("updatedTipi", tipiList);
    },
    updateColori1(context, coloriList1) {
      context.commit("updatedColori1", coloriList1);
    },
    updateColori2(context, coloriList2) {
      context.commit("updatedColori2", coloriList2);
    },
    updateFiniture1(context, finitureList1) {
      context.commit("updatedFiniture1", finitureList1);
    },
    updateFiniture2(context, finitureList2) {
      context.commit("updatedFiniture2", finitureList2);
    },
    updateSpessori(context, spessoriList) {
      context.commit("updatedSpessori", spessoriList);
    },
    updateLunghezze(context, lunghezzeList) {
      context.commit("updatedLunghezze", lunghezzeList);
    },
    updateLarghezze(context, larghezzeList) {
      context.commit("updatedLarghezze", larghezzeList);
    },
    updateCollezioni(context, collezioniList) {
      context.commit("updatedCollezioni", collezioniList);
    },
  },
  mutations: {
    updatedTipi(state, tipiList) {
      state.prod.tipi = tipiList;
    },
    updatedColori1(state, coloriList1) {
      state.prod.colori1 = coloriList1;
    },
    updatedColori2(state, coloriList2) {
      state.colori2 = coloriList2;
    },
    updatedFiniture1(state, finitureList1) {
      state.finiture1 = finitureList1;
    },
    updatedFiniture2(state, finitureList2) {
      state.finiture2 = finitureList2;
    },
    updatedSpessori(state, spessoriList) {
      state.spessori = spessoriList;
    },
    updatedLunghezze(state, lunghezzeList) {
      state.lunghezze = lunghezzeList;
    },
    updatedLarghezze(state, larghezzeList) {
      state.larghezze = larghezzeList;
    },
    updatedCollezioni(state, collezioniList) {
      state.collezioni = collezioniList;
    },
  },
  getters: {
    tipi(state) {
      return state.prod.tipi;
    },
    colori1(state) {
      return state.prod.colori1;
    },
    colori2(state) {
      return state.prod.colori2;
    },
    finiture1(state) {
      return state.prod.finiture1;
    },
    finiture2(state) {
      return state.prod.finiture2;
    },
    spessori(state) {
      return state.prod.spessori;
    },
    lunghezze(state) {
      return state.prod.lunghezze;
    },
    larghezze(state) {
      return state.prod.larghezze;
    },
    collezioni(state) {
      return state.prod.collezioni;
    },
  },
};
